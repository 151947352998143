<template>
    <h3 class="tw-text-xl tw-font-serif tw-leading-6 tw-text-chards-gold">
        Subscribe to Our Newsletter
    </h3>
    <p class="tw-mt-2 tw-text-sm tw-leading-6 tw-text-white tw-font-sans">
        Receive the latest coin and bullion news, exclusive product offers, and early releases.
    </p>
    <form
        id="newsletter-footer-form"
        class="tw-mt-6 sm:tw-flex sm:tw-max-w-md"
        :action="url"
        method="get"
    >
        <AppInput
            id="newslettersignupform-email"
            v-model="email"
            :required="true"
            name="NewsletterSignupForm[email]"
            type="email"
            aria-label="Email input"
            placeholder="Enter your email"
            aria-required="true"
            classes="tw-w-full tw-min-w-0 tw-appearance-none tw-rounded-md tw-border-0 tw-bg-white tw-px-3 tw-py-1.5 tw-text-base tw-text-chards-purple tw-font-sans placeholder:tw-text-chards-purple sm:tw-w-64 sm:tw-text-sm sm:tw-leading-6 xl:tw-w-full"
        />
        <div class="tw-mt-4 sm:tw-ml-4 sm:tw-mt-0 sm:tw-flex-shrink-0">
            <AppButton
                size="small"
                color="white"
                class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-px-3 tw-py-2 tw-font-semibold tw-shadow-sm"
                type="submit"
            >
                Subscribe
            </AppButton>
        </div>
    </form>
</template>

<script>
import AppButton from '@app/atoms/AppButton.vue';
import AppInput from '@app/atoms/AppInput.vue';

export default {
    name: 'NewsletterSignup',
    components: {
        AppButton,
        AppInput
    },
    data() {
        return {
            url: import.meta.env.PUBLIC_LEGACY_URL + 'newsletter/sign-up',
            email: ''
        };
    },
    methods: {
    }
};
</script>
